/*------------------------------------
  Indicator Dots
------------------------------------*/

.u-indicator-dots {
	position: relative;

	@include media-breakpoint-up(md) {
		&::after {
			position: absolute;
			right: -2.1875rem;
			top: 50%;
			width: 2.4375rem;
			height: .75rem;
			background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 16'%3E %3Ccircle fill='#{$gray-300}' cx='27.7' cy='8.1' r='7.7'/%3E %3Ccircle fill='#{$gray-300}' cx='5' cy='8.1' r='5'/%3E %3Ccircle fill='#{$gray-300}' cx='50' cy='8.1' r='5'/%3E %3C/svg%3E"), "#", "%23");
			background-repeat: no-repeat;
			content: "";
			margin-top: -.375rem;
		}
	}
}