/*------------------------------------
  SVG IE10+ specific styles go here
------------------------------------*/
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .u-header__promo-icon {
    height: 48px;
  }

  .ie-height-24 {
    height: 24px;
  }

  .ie-height-40 {
    height: 40px;
  }

  .ie-height-48 {
    height: 48px;
  }

  .ie-height-56 {
    height: 56px;
  }

  .ie-height-72 {
    height: 72px;
  }

  .ie-main-hero {
    height: 686px;
  }

  .ie-soft-triangle-shape {
    height: 610px;
  }

  .ie-browser {
    height: 565px;
  }

  .ie-wave-1-bottom {
    height: 100px;
  }

  .ie-vault,
  .ie-responsive,
  .ie-easy-payment {
    height: 210px;
  }

  .ie-marketing-strategy,
  .ie-app-development,
  .ie-business-analysis {
    height: 328px;
  }

  .ie-devices-v1-tablet {
    height: 38.5rem;
  }

  .ie-devices-v1-phone {
    height: 27.5rem;
  }

  .ie-devices-v2-tablet {
    height: 37.875rem;
  }

  .ie-devices-v2-iphone {
    height: 31rem;
  }

  .ie-devices-v3-iphone {
    height: 633px;
  }

  .ie-devices-v4-tablet-horizontal {
    height: 16.875rem;
  }

  .ie-devices-v4-tablet {
    height: 25.4375rem;
  }

  .ie-devices-v4-laptop {
    height: 20.75rem;
  }

  .ie-devices-v4-iphone {
    height: 13.0625rem;
  }

  .ie-wave-6-top-left {
    height: 379px;
  }

  .ie-double-ellipse-top-right {
    height: 431px;
  }

  .ie-double-ellipse-top-left {
    height: 788px;
  }

  .ie-double-ellipse-bottom-right {
    height: 252px;
  }

  .ie-ellipse-mockup {
    height: 656px;
  }

  .ie-irregular-shape-2-right {
    height: 660px;
  }

  .ie-irregular-shape-3-bottom {
    height: 255px;
  }

  .ie-circle-chart {
    height: 219px;
  }

  .ie-curved-shape {
    height: 55.8px;
  }

  .ie-subscribe-illustration {
    height: 329px;
  }

  .ie-subscribe-1 {
    height: 315px;
  }

  .ie-subscribe-2 {
    height: 295px;
  }

  .ie-subscribe-2-flat-icons {
    height: 200px;
  }

  .ie-color-gradient {
    height: 566px;
  }

  .ie-for-sale,
  .ie-buyer {
    height: 208px;
  }

  .ie-events,
  .ie-data-report,
  .ie-image-upload {
    height: 219px;
  }

  .ie-analysis,
  .ie-in-the-office,
  .ie-make-it-rain {
    height: 200px;
  }

  .ie-house-agency {
    height: 381px;
  }

  .ie-laptop-and-iphone {
    height: 421px;
  }

  .ie-get-answered {
    height: 386px;
  }

  .ie-bg-elements-1 {
    height: 420px;
  }

  .ie-bg-elements-2 {
    height: 374px;
  }

  .ie-bg-elements-3 {
    height: 583px;
  }

  .ie-bg-elements-4 {
    height: 850px;
  }

  .ie-circle-1 {
    height: 379px;
  }

  .ie-go-to-wave {
    height: 46px;
  }

  .ie-graphic-illustration-1 {
    height: 270px;
  }

  .ie-app-development {
    height: 328px;
  }

  .ie-we-have-an-idea {
    height: 335px;
  }

  .ie-chatting-girl,
  .ie-chatting-boy {
    height: 328px;
  }

  .ie-virtual-reality {
    height: 320px;
  }

  .ie-maintenance-mode {
    height: 200px;
  }

  .ie-non-standard-hero-shape {
    height: 556px;
  }

  .ie-enterprise-2 {
    height: 267px;
  }

  .ie-abstract-shapes-1 {
    height: 554px;
  }

  .ie-abstract-shapes-2,
  .ie-abstract-shapes-3,
  .ie-abstract-shapes-4 {
    height: 532px;
  }

  .ie-abstract-shapes-6 {
    height: 187px;
  }

  .ie-abstract-shapes-7 {
    height: 624px;
  }

  .ie-abstract-shapes-9 {
    height: 182px;
  }

  .ie-abstract-shapes-10 {
    height: 573px;
  }

  .ie-abstract-shapes-11 {
    height: 192px;
  }

  .ie-showcase-mockup-1 {
    height: 384px;
  }

  .ie-showcase-mockup-2 {
    height: 371px;
  }

  .ie-showcase-mockup-3 {
    height: 535px;
  }

  .ie-knowledgebase-community {
    height: 447px;
  }

  .ie-knowledgebase-community-2 {
    height: 542px;
  }

  .ie-files {
    height: 293px;
  }
}