@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:500');
@import 'bootstrap/scss/functions';
@import 'theme/front/variables';
@import 'bootstrap/scss/bootstrap';
@import 'theme';
@import 'highlight';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-link {
  font-size: 1.05em;
  font-weight: 500;
  color: $gray-700;
  padding-bottom: 0;

  &:hover {
    color: $gray-900;
  }
}

.nav-item {
  margin-left: 15px;
}

.dropdown-menu {
  border-top: 3px solid $gray-300;
  border-radius: 0;
  min-width: 175px;
}

.team-avatar {
  border: 1px solid $gray-400;
  overflow: hidden;
  width: 120px;
  height: 120px;

  img {
    margin: 5px 0 0 -25px;
    width: 175px;
  }
}

.gradient-half-gray {
  @include gradient-x($hs-start-color: $gray-800, $hs-end-color: $gray-600, $deg: 150deg);
}

.gradient-aquamarine {
  @include gradient-x($hs-start-color: #1A2980, $hs-end-color: #26D0CE, $deg: 150deg);
}

.gradient-moss {
  @include gradient-x($hs-start-color: #134E5E, $hs-end-color: #71B280, $deg: 150deg);
}

.gradient-reef {
  @include gradient-x($hs-start-color: #3a7bd5, $hs-end-color: #00d2ff, $deg: 150deg);
}

.gradient-mojito {
  @include gradient-x($hs-start-color: #1D976C, $hs-end-color: #93F9B9, $deg: 150deg);
}

.gradient-quepal {
  @include gradient-x($hs-start-color: #11998e, $hs-end-color: #38ef7d, $deg: 150deg);
}

.gradient-namn {
  @include gradient-x($hs-start-color: #7a2828, $hs-end-color: #a73737, $deg: 150deg);
}

.gradient-love {
  @include gradient-x($hs-start-color: #3a6186, $hs-end-color: #89253e, $deg: 150deg);
}

.gradient-sky {
  @include gradient-x($hs-start-color: #363795, $hs-end-color: #005C97, $deg: 150deg);
}

#press {
  line-height: 1.8em;
}

#header {
  border-bottom: 3px solid $gray-300;
}

.badge-client {
  background: $gray-300;
  color: $gray-700;
  font-weight: 600;
}

.list-group-item {
  color: $gray-800;
  padding-left: 5px;

  &.active {
    color: $gray-800;
    background-color: #ffffff;
    border-right: 3px solid $primary;
  }
}

#documentation {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 1em;
  color: $gray-800;

  h1, h2, h3, h4, h5 {
    font-weight: 600;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
  }

  h4 {
    margin: 35px 0 10px;
    font-weight: 500;
  }

  h3 {
    margin: 50px 0 10px;
  }

  pre {
    padding: 10px;
    border: 1px solid $gray-300;

    code {
      padding: 0;
      font-weight: 500;
    }
  }

  #code-example pre {
    border: 1px solid $gray-300;
    border-top: none;
  }

  #path {
    margin-top: 35px;
    font-family: monospace;
  }

  code {
    background-color: #f8fafd;
    padding: 5px;
    font-weight: 600;
  }
}

#documentation-navigation {
  h5.heading {
    font-weight: 600;
    color: $gray-800;
    margin: 15px 0 0 0;
    border-bottom: 1px solid $gray-300;
    cursor: pointer;
  }

  h5.subhead {
    @extend h5, .heading;
    border-bottom: 1px solid $gray-300;
    font-size: 1em;
    color: $gray-600;
    margin: 10px 0 0 0;
  }

  p.description {
    font-weight: 400;
    font-size: 0.8em;
    color: $gray-600;
    line-height: 20px;
  }

  a.active {
    font-weight: bold;
  }
}

.method {
  color: $white;
  text-transform: uppercase;
  font-size: 0.5em;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
  height: 13px;
  background-color: #333;
  border-radius: 3px;
  vertical-align: middle;
  margin-right: 7px;
  margin-bottom: 3px;
  text-align: center;
  font-weight: 600;
  padding-top: 1px;

  &.get {
    background: $primary;
  }

  &.put {
    background: $warning;
  }

  &.del {
    background: $danger;
  }

  &.post {
    background: $success;
  }

  &.info {
    background: $info;
  }
}

.bg-img-hero .algolia-autocomplete {
  width: 100%;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}
