/*------------------------------------
  Typography Font Size
------------------------------------*/

.font-size-1 {
  font-size: ($font-size-base * .875);
}

.font-size-2 {
  font-size: ($font-size-base * 1.5);
}

.font-size-3 {
  font-size: ($font-size-base * 2);
}

.font-size-4 {
  font-size: ($font-size-base * 2.5);
}

.font-size-5 {
  font-size: ($font-size-base * 3);
}

// Medium Devices
@include media-breakpoint-down(md) {
  .font-size-md-down-5 {
    font-size: ($font-size-base * 3);
  }
}

/*------------------------------------
  Font Weight
------------------------------------*/
.font-weight-medium {
  font-weight: $font-weight-medium !important;
}

.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}

/*------------------------------------
  Line Height
------------------------------------*/

.text-lh-sm {
  line-height: 1.2;
}

.text-lh-md {
  line-height: 1.8;
}